.breadcrumb {
    width: 90%;
}

.image-div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.chart-div {
}

.chart-line-cap-div {
    z-index: 99999999;
    position: absolute;
}

.button {
  margin-top: -190px;
}

.firstButton {
    margin-top: -170px;
}

.dropdown-container {
    z-index: 5;
    position: fixed;
    top: 10px;
    display: inline-block;
}


.dropdown-container {
    z-index:5;
    position: fixed;
    margin-top: 20%;
    display: inline-block;
}

.dropdown-button {
    z-index: 5;
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: #f1f1f1;
    cursor: pointer;
}

.dropdown-icon {
    margin-left: 5px;
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-top: none;
}

.dropdown-list-item {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
}

.dropdown-list-item:hover {
    background-color: #ddd;
}


.magnify {
    position: absolute;

}

.magnify-image {
    display: block;
    width: 100%;
    height: auto;
}

.magnify-glass {
    position: absolute;
    width: 20vw;
    height: 35vh;
    border-radius: 100%;
    border: 3px solid white;
    cursor: none;
    background-repeat: no-repeat;
    background-size: 500% 500%;
    visibility: hidden;
    pointer-events: none;
    margin-top: -40vh;
    margin-left: 2vh
}

.magnify-glass:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4vw;
    height: 4vw;
    margin: -2vw 0 0 -2vw;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.27);
    opacity: 0.5;
}