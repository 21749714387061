 
.tower {
  margin-left: calc((100% - 60%) / 2); /* Replaced @tower-width with its value */
  width: 40%;

  .title {
      font-size: 24px;
      margin-top: 25px;
  }

  .small_title {
      margin-top: 10px;
      font-size: 18px;
  }

  .text {
      color: rgba(0, 0, 0, 0.5);
  }
}

.home-page-footer {
  width: 100%;
  height: 200px;
  background: #474747;

  .footer-text {
      margin-left: calc((100% - 60%) / 2); /* Replaced @tower-width with its value */
      width: 40%;
      color: white;

      .addresses {
          width: 50%;
      }

      a {
          color: white;
      }

      .contact_us {
          font-size: 24px;
          color: dodgerblue;
      }
  }
}

