.st-layout {
  padding: 0 24px 24px;
}

.side-menu {
  width: 200px;
  height: 100%;
}

.main-content {
  padding: 4px;
  margin: 0;
  min-height: 280px;
}

.menu {
  height: 100%;
}

.breadcrumb {
  margin: 16px 0;
}

.collabsible-layout{
  min-height: 130vh;
  min-width: 100%;
}

.main-layout {
  height: 100vh;
}

.main-content{
  height: 100vh;
}

.logo {
  float: left;
  width: 120px;
  height: 55px;
  margin: 2px 16px 2px 0;
  overflow: hidden;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header {
  z-index: 100;
}

.landing-image {
  height: calc(100vh - 64px);
  width: auto;
  
}

.landing-image-div {
  width: auto;
  background-color: rgba(0, 0, 0, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer { 
  text-align: 'center';
  padding: '20px';
  background-color: '#f0f2f5'; 
}

