.Line-chart {
    padding: 4px;
    width: 100%;
    height: 600px;
  }

.label-selector {
    width: 100%;
    padding: 6px 6px 6px 0;
}
