.Line-chart {
  width: 90%;
  min-height: calc(22vh + 5vw);
  margin-left: 5%;
}
.menu {
  height: 100%;
}


.ts-content{
  z-index: 3;
}