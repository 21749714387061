.Scatter-chart {
  padding: 4px;
  width: 100%;
  height: 600px;
}

.EmbeddingView {
  padding: 4px;
}

.FloatBtnPlay{
  position: absolute;
}


.FillParent{
  width: 100%;
}

.Selector{
  padding-top: 12px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}

.ExplanationPanel{
  width: 100%;
  padding: 4px;
}

.Animationvideo{
  width: 100%;
  height: 100%;
}

.ExplanationText{
  height: 100%;
  width: 100%;
  padding: 16px;
  h1 {
    white-space: break-spaces;
  }
}

.Tabs{
  height: 100%;
}

.ExpImageW{
  width: 100%;
  height: auto;
}

/* Update the hight so that it fits the parent*/
.ExpImageH{
  width: auto;
  height: 100%;
}

